.ContactForm {
  display: flex;
  flex-direction: column;
  position: relative;

  &-name,
  &-email,
  &-firma,
  &-phone,
  &-message,
  &-confirmCheckbox {
    margin-top: 2em;
  }

  &-message {
    grid-column: 1 / 3;
  }

  &-controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
  }

  &-sendDescription {
    text-align: center;
    margin-top: 4em;
    font-size: var(--fontSize--m);
  }

  &-submit {
    width: 200px;
    align-self: center;
    margin-top: 1em;
  }
}
