$scope: '.Header';

#{$scope} {
  display: flex;
  justify-content: space-between;
  padding: 2em 4em;
  max-width: var(--maxFluidWidth);
  width: 100%;

  &-ligaos {
    width: 140px;
  }

  &-linksList {
    display: flex;
    align-items: center;
    padding: 0;
  }

  &-linksListItem {
    display: flex;
    color: var(--color-gray-2);

    &--highlighted {
      color: var(--color-white);
    }
  }

  &-link {
    font-size: var(--fontSize--l);
    font-family: 'LatoWebBold', sans-serif;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    margin-left: 1em;

    &:hover,
    &:active,
    &:focus {
      #{$scope}-linkText {
        width: fit-content;
        background-image: var(--background-themeColorGradient);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &--isActive {
      display: none;
    }
  }

  &--isMobileMode {
    padding: 1em;

    #{$scope}-linkText {
      font-size: var(--fontSize--m);
    }

    #{$scope}-ligaos {
      width: 84px;
    }
  }
}
