$scope: '.ContactPage';

#{$scope} {
  display: flex;
  flex-direction: column;
  padding: 4em 6em 5em;
  position: relative;

  &-pageTitle {
    margin-top: 0.1em;
    font-size: var(--fontSize--xxl);
    font-family: 'FuturaStd-Heavy', sans-serif;
    align-self: center;
    width: fit-content;
    background-image: var(--background-themeColorGradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-bottomPageDescription {
    font-size: 1.25rem;
    font-family: 'FuturaStd-Heavy', sans-serif;
    letter-spacing: 2px;
    width: fit-content;
    background-image: var(--background-themeColorGradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: rotate(270deg) translate(0, -100%);
    transform-origin: 0 0;
    position: relative;
    right: 2em;
  }

  &-pageDescriptionGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    margin-top: 2em;
    justify-items: center;
  }

  &-descriptionTitle {
    font-size: var(--fontSize--xl);
    font-family: 'LatoWebBlack', sans-serif;
    margin-top: 1.6em;
  }

  &-description {
    font-size: var(--fontSize--m);
    font-family: 'FuturaStd-Light', sans-serif;
    color: var(--color-gray-1);
    margin-top: 0.4em;
    line-height: 1.8;
  }

  &--isMobileMode {
    padding: 0 1em 3em;
    overflow: auto;

    #{$scope}-pageDescriptionGrid {
      grid-template-columns: 1fr;
      justify-items: left;
    }
  }
}
