$scope: '.Footer';

#{$scope} {
  background-color: var(--color-black);
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em 0;
  width: 100%;

  &-bottomGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: flex-end;
    width: 100%;
  }

  &-contactUs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-contactUsLink {
    font-size: var(--fontSize--xxl);
    margin-top: 0.2em;
    text-decoration: none;
    color: var(--color-white);

    &:hover,
    &--isActive {
      text-decoration: underline;
    }
  }

  &-socialList {
    display: flex;
    padding: 0;
    list-style-type: none;
    justify-content: center;
    align-items: flex-end;
  }

  &-socialListItem + &-socialListItem {
    margin-left: 1em;
  }

  &-linksList {
    display: flex;
    padding: 0;
    list-style-type: none;
    justify-content: center;
    align-items: flex-end;
  }

  &-link {
    text-decoration: none;
    color: var(--color-white);
    font-size: 1.25rem;
    font-family: 'FuturaStd-Light', sans-serif;

    &:hover,
    &--isActive {
      text-decoration: underline;
    }
  }

  &-linksListItem + &-linksListItem {
    margin-left: 2em;
  }

  &-socialListItemImage {
    width: 24px;
  }

  &-brand {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 3;
    margin-top: 1.6em;
    min-width: 170px;
  }

  &-brandIcon {
    max-width: 200px;
  }

  &-brandDescription {
    font-size: var(--fontSize--s);
    font-family: 'FuturaStd-Light', sans-serif;
  }

  &--isMobileMode {
    padding: 1em;

    #{$scope}-contactUsLink {
      font-size: var(--fontSize--xl);
    }

    #{$scope}-link {
      font-size: 12px;
    }

    #{$scope}-linksListItem + #{$scope}-linksListItem {
      margin-left: 1em;
    }
  }
}
