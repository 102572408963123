.Textarea {
  display: flex;
  border-radius: 12px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);

  &-control {
    flex: 1;
    width: 100%;
    background-color: transparent;
    border: none;
    font-family: 'FuturaStd-Book', sans-serif;
    color: var(--color-gray-1);
    font-size: var(--fontSize--m);
    padding: 0.7em;
    border-radius: inherit;
    background-color: var(--color-gray-3);
    overflow: hidden;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px var(--color-white);
    }
  }
}
