html {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--color-black);
}

body {
  background-image: var(--background-grayGradient);
  color: var(--color-white);
  font-size: var(--fontSize--m);
  font-family: 'FuturaStd-Book', sans-serif;
  letter-spacing: 0.2px;
  margin: 0;
}

html,
body,
body > navlink,
.Root {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

svg {
  fill: currentColor;
}

button {
  cursor: pointer;
}

::-webkit-input-placeholder {
  color: var(--color-gray-2);
  font-style: italic;
}

::-moz-placeholder {
  color: var(--color-gray-2);
  font-style: italic;
}

:-ms-input-placeholder {
  color: var(--color-gray-2);
  font-style: italic;
}

:-moz-placeholder {
  color: var(--color-gray-2);
  font-style: italic;
}

ul {
  padding-inline-start: 1em;
}
