$scope: '.Pricing';

#{$scope} {
  display: flex;
  flex-direction: column;
  padding: 4em 6em 5em;
  position: relative;

  &-sectionGrid {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-column-gap: 2em;
  }

  &-sectionTitle {
    font-family: 'FuturaStd-Heavy', sans-serif;
    width: fit-content;
    background-image: var(--background-themeColorGradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: var(--fontSize--xxl);
  }

  &-descriptionTitle {
    font-size: var(--fontSize--xl);
    margin-top: 0.6em;
    font-family: 'LatoWebBlack', sans-serif;
  }

  &-getInTouch {
    padding-top: 1.6em;
  }

  &-description {
    color: var(--color-gray-1);
    font-family: 'FuturaStd-Light', sans-serif;
    line-height: 1.6;
    margin-top: 0.4em;
    margin-right: 4em;
  }

  &-priceSection {
    background-color: var(--color-gray-3);
    border-radius: 16px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.4);
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-priceSectionDescription {
    padding: 1em;
    text-align: center;
    color: #868e9c;
    font-family: 'LatoWebMedium', sans-serif;
  }

  &-ligaosAndSap {
    width: 250px;
  }

  &-prefix {
    font-size: 2.5rem;
    font-family: 'LatoWebMedium', sans-serif;
  }

  &-priceText {
    align-self: self-start;
  }

  &-price {
    font-size: var(--fontSize--xxxl);
    font-family: 'LatoWebBold', sans-serif;
  }

  &-priceDescription {
    font-size: 2.5rem;
    font-family: 'LatoWebMedium', sans-serif;
    font-size: var(--fontSize--xl);
  }

  &-priceList {
    padding: 0;
  }

  &-priceListItem {
    margin-top: 1.6em;
    color: var(--color-gray-1);
    display: flex;
    align-items: self-start;
  }

  &-period {
    margin: 0 auto 1em auto;
    display: none;
  }

  &-checkmark {
    min-width: 22px;
    max-width: 22px;
    margin-right: 0.6em;
  }

  &-bottomPageDescription {
    font-size: 1.25rem;
    font-family: 'FuturaStd-Heavy', sans-serif;
    letter-spacing: 2px;
    width: fit-content;
    background-image: var(--background-themeColorGradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: rotate(270deg) translate(0, -100%);
    transform-origin: 0 0;
    position: relative;
    right: 2em;
  }

  &--isMobileMode {
    padding: 0 1em 3em;
    overflow: auto;

    #{$scope}-sectionGrid {
      grid-template-columns: 1fr;
    }

    #{$scope}-priceSection {
      padding: 1em;
    }

    #{$scope}-price {
      font-size: var(--fontSize--xxl);
    }

    #{$scope}-description {
      margin-right: 0;
    }

    #{$scope}-priceSection {
      margin-top: 1.6em;
    }

    #{$scope}-bottomPageDescription {
      display: none;
    }
  }
}
