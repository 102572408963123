@font-face {
  font-family: 'FuturaStd-Book';
  src: url('./FuturaStd-Book.eot');
  src: url('./FuturaStd-Book.eot?#iefix') format('embedded-opentype'),
    url('./FuturaStd-Book.woff2') format('woff2'),
    url('./FuturaStd-Book.woff') format('woff'),
    url('./FuturaStd-Book.ttf') format('ttf'),
    url('./FuturaStd-Book.svg#FuturaStd-Book') format('svg');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'FuturaStd-Light';
  src: url('./FuturaStd-Light.eot');
  src: url('./FuturaStd-Light.eot?#iefix') format('embedded-opentype'),
    url('./FuturaStd-Light.woff2') format('woff2'),
    url('./FuturaStd-Light.woff') format('woff'),
    url('./FuturaStd-Light.ttf') format('ttf'),
    url('./FuturaStd-Light.svg#FuturaStd-Light') format('svg');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'FuturaStd-Medium';
  src: url('./FuturaStd-Medium.eot');
  src: url('./FuturaStd-Medium.eot?#iefix') format('embedded-opentype'),
    url('./FuturaStd-Medium.woff2') format('woff2'),
    url('./FuturaStd-Medium.woff') format('woff'),
    url('./FuturaStd-Medium.ttf') format('ttf'),
    url('./FuturaStd-Medium.svg#FuturaStd-Medium') format('svg');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'FuturaStd-Heavy';
  src: url('./FuturaStd-Heavy.eot');
  src: url('./FuturaStd-Heavy.eot?#iefix') format('embedded-opentype'),
    url('./FuturaStd-Heavy.woff2') format('woff2'),
    url('./FuturaStd-Heavy.woff') format('woff'),
    url('./FuturaStd-Heavy.ttf') format('ttf'),
    url('./FuturaStd-Heavy.svg#FuturaStd-Heavy') format('svg');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'FuturaStd-Bold';
  src: url('./FuturaStd-Bold.eot');
  src: url('./FuturaStd-Bold.eot?#iefix') format('embedded-opentype'),
    url('./FuturaStd-Bold.woff2') format('woff2'),
    url('./FuturaStd-Bold.woff') format('woff'),
    url('./FuturaStd-Bold.ttf') format('ttf'),
    url('./FuturaStd-Bold.svg#FuturaStd-Bold') format('svg');
  font-style: normal;
  font-weight: normal;
}
