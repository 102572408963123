$scope: '.TimeBoard';

#{$scope} {
  display: flex;
  flex-direction: column;
  padding: 4em 6em 5em;
  position: relative;

  &-sectionTitle {
    text-align: center;
    font-size: var(--fontSize--xxxl);
    font-family: 'FuturaStd-Bold', sans-serif;
  }

  &-sectionDescription {
    text-align: center;
    font-size: var(--fontSize--xl);
    max-width: 800px;
    align-self: center;
    margin-top: 0.4em;
  }

  &-timeBoardGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    grid-column-gap: 2em;
    margin-top: 3em;
  }

  &-timeBoardImage {
    max-width: 420px;
    width: 100%;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.8);
    border-radius: 14px;
  }

  &-holidaysImage,
  &-teamOverviewImage {
    max-width: 544px;
    width: 100%;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.8);
    border-radius: 14px;
    margin-top: 2em;
  }

  &-timeBoardTitle,
  &-abstractionTitle,
  &-timeControllingTitle {
    font-family: 'FuturaStd-Heavy', sans-serif;
    width: fit-content;
    background-image: var(--background-themeColorGradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: var(--fontSize--xxl);
    margin-top: 1em;
  }

  &-listTitle {
    font-size: var(--fontSize--l);
    margin-top: 1em;
    font-family: 'LatoWebBlack', sans-serif;
  }

  &-bottomDescription {
    font-family: 'FuturaStd-Bold', sans-serif;
    line-height: 1.4;
    min-height: 50px;
  }

  &-description {
    color: var(--color-gray-1);
    font-family: 'FuturaStd-Light', sans-serif;
    margin-top: 0.4em;
    line-height: 1.4;
  }

  &-timeBoardInfoColumn {
    display: flex;
    flex-direction: column;
  }

  &-bottomPageDescription {
    font-size: 1.25rem;
    font-family: 'FuturaStd-Heavy', sans-serif;
    letter-spacing: 2px;
    width: fit-content;
    background-image: var(--background-themeColorGradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: rotate(270deg) translate(0, -100%);
    transform-origin: 0 0;
    position: relative;
    right: 2em;
  }

  &--isMobileMode {
    padding: 0 1em 3em;
    overflow: auto;

    #{$scope}-timeBoardGrid {
      grid-template-columns: 1fr;
    }

    #{$scope}-bottomDescription {
      min-height: auto;
      margin-top: 0.4em;
    }

    #{$scope}-abstractionTitle {
      margin-top: 0;
    }

    #{$scope}-timeBoardTitle,
    #{$scope}-timeControllingTitle {
      margin-top: 0.4em;
    }

    #{$scope}-sectionTitle {
      font-size: var(--fontSize--xxl);
    }

    #{$scope}-timeBoardImage {
      margin-top: 1em;
    }

    #{$scope}-bottomPageDescription {
      display: none;
    }

    #{$scope}-timeBoardTitle,
    #{$scope}-timeControllingTitle {
      line-height: 1.1;
      padding-bottom: 0.2em;
    }
  }
}
