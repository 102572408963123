$scope: '.AboutUs';

#{$scope} {
  display: flex;
  flex-direction: column;
  padding: 4em 6em 5em;
  position: relative;

  &-sectionTitle {
    font-family: 'FuturaStd-Heavy', sans-serif;
    width: fit-content;
    background-image: var(--background-themeColorGradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: var(--fontSize--xxl);
  }

  &-sectionDescription {
    font-size: var(--fontSize--m);
    margin-top: 1em;
    line-height: 1.6;
    padding-right: 20em;
  }

  &-teamGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2em;
    margin-top: 4em;
  }

  &-teamName {
    margin-top: 1.4em;
    font-size: var(--fontSize--m);
  }

  &-quote {
    padding: 0 2em;
    position: relative;
    font-size: 14px;
    line-height: 1.6;
    margin-top: 2em;
    padding-bottom: 1em;
  }

  &-teamSection {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    padding: 1em;

    &:before {
      content: '';
      position: absolute;
      top: 90px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background-color: var(--color-gray-3);
      border-radius: 16px;
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.8);
    }
  }

  &-teamImage {
    max-width: 140px;
    width: 100%;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.8);
    border-radius: 50%;
  }

  &-description {
    margin-top: 0.4em;
    color: var(--color-gray-1);
    font-family: 'FuturaStd-Light', sans-serif;
  }

  &-all4Cloud,
  &-ligadigital,
  &-liganova {
    width: 212px;
  }

  &-quotes {
    width: 24px;
    position: absolute;
    left: 4px;
    top: 0;
  }

  &-bottomPageDescription {
    font-size: 1.25rem;
    font-family: 'FuturaStd-Heavy', sans-serif;
    letter-spacing: 2px;
    width: fit-content;
    background-image: var(--background-themeColorGradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: rotate(270deg) translate(0, -100%);
    transform-origin: 0 0;
    position: relative;
    right: 2em;
  }

  &--isMobileMode {
    padding: 0 1em 3em;
    overflow: auto;

    #{$scope}-teamGrid {
      grid-template-columns: 1fr;
      margin-top: 1.6em;
    }

    #{$scope}-sectionDescription {
      padding-right: 0;
    }

    #{$scope}-bottomPageDescription {
      display: none;
    }
  }
}
