.RadioGroup {
  display: flex;
  border-radius: 20px;
  background-color: #2f3136;
  width: max-content;

  &-label {
    cursor: pointer;
    position: relative;
    font-family: 'LatoWebMedium', sans-serif;
    padding: 0.4em 1em;
    border-radius: inherit;
    color: var(--color-gray-2);
  }

  &--checked {
    background-image: var(--background-themeColorGradient);
    color: var(--color-white);
  }

  &-input {
    visibility: hidden;
    position: absolute;
  }
}
