$scope: '.Imprint';

#{$scope} {
  display: flex;
  flex-direction: column;
  padding: 4em 6em 5em;
  position: relative;

  &-pageTitle {
    font-size: var(--fontSize--xxl);
    width: fit-content;
    background-image: var(--background-themeColorGradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'FuturaStd-Heavy', sans-serif;
  }

  &-descriptionTitle {
    font-size: var(--fontSize--xl);
    font-family: 'LatoWebBlack', sans-serif;
    margin-top: 1em;
  }

  &-description {
    font-size: var(--fontSize--m);
    font-family: 'FuturaStd-Light', sans-serif;
    color: var(--color-gray-1);
    margin-top: 0.4em;
    line-height: 1.8;
  }

  &-bottomPageDescription {
    font-size: 1.25rem;
    font-family: 'FuturaStd-Heavy', sans-serif;
    letter-spacing: 2px;
    width: fit-content;
    background-image: var(--background-themeColorGradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: rotate(270deg) translate(0, -100%);
    transform-origin: 0 0;
    position: relative;
    right: 2em;
  }

  &--isMobileMode {
    padding: 0 1em 3em;
    overflow: auto;

    #{$scope}-description {
      word-break: break-word;
    }

    #{$scope}-pageTitle {
      font-size: 32px;
    }

    #{$scope}-descriptionTitle {
      font-size: var(--fontSize--l);
    }
  }
}
