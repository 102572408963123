.Button {
  display: flex;

  &-control {
    flex: 1;
    width: 100%;
    border-radius: 12px;
    padding: 0.6em;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 3px transparent;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      var(--background-themeColorGradient);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px var(--color-gray-4) inset;
    color: var(--color-white);
    cursor: pointer;
    transition: 0.3s box-shadow;
    font-family: 'LatoWebBold', sans-serif;
    font-size: inherit;
    position: relative;

    &:disabled {
      opacity: 0.6;
      cursor: default;
    }

    &:not(:disabled) {
      &:hover,
      &:active {
        box-shadow: none;
      }
    }

    &:before {
      content: '';
      box-shadow: 0 0 0 1px var(--color-white);
      position: absolute;
      pointer-events: none;
      top: -3px;
      left: -3px;
      right: -3px;
      bottom: -3px;
      border-radius: inherit;
      opacity: 0;
    }

    &:focus {
      outline: none;
      &:before {
        opacity: 1;
      }
    }
  }
}
