$scope: '.EX3000';

#{$scope} {
  display: flex;
  flex-direction: column;
  padding: 4em 6em 5em;
  position: relative;
  line-height: 1.6;

  &-sectionTitle {
    text-align: center;
    font-size: var(--fontSize--xxxl);
    font-family: 'FuturaStd-Bold', sans-serif;
  }

  &-sectionDescription {
    text-align: center;
    font-size: var(--fontSize--xl);
    max-width: 600px;
    align-self: center;
  }

  &-projectOverviewGrid,
  &-calculationGrid,
  &-staffingGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2em;
    margin-top: 3em;
    justify-items: center;
  }

  &-projectOverviewGrid {
    margin-top: 6em;
  }

  &-projectOverviewImage,
  &-calculationImage {
    max-width: 460px;
  }

  &-projectOverviewImage,
  &-calculationImage,
  &-staffingImage {
    width: 100%;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.8);
    border-radius: 14px;
    align-self: center;
  }

  &-projectOverviewTitle,
  &-calculationTitle,
  &-staffingTitle {
    font-family: 'FuturaStd-Heavy', sans-serif;
    width: fit-content;
    background-image: var(--background-themeColorGradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: var(--fontSize--xxl);
  }

  &-projectOverviewDescription,
  &-calculationDescription {
    color: var(--color-gray-1);
    font-family: 'FuturaStd-Light', sans-serif;
  }

  &-bottomPageDescription {
    font-size: 1.25rem;
    font-family: 'FuturaStd-Heavy', sans-serif;
    letter-spacing: 2px;
    width: fit-content;
    background-image: var(--background-themeColorGradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: rotate(270deg) translate(0, -100%);
    transform-origin: 0 0;
    position: relative;
    right: 2em;
  }

  &-listTitle {
    font-size: var(--fontSize--l);
    margin-top: 1em;
    font-family: 'LatoWebBlack', sans-serif;
  }

  &-projectOverviewInfoColumn {
    display: flex;
    flex-direction: column;
  }

  &-calculationSubTitle {
    font-family: 'FuturaStd-Bold', sans-serif;
  }

  &--isMobileMode {
    padding: 0 1em 3em;
    overflow: auto;

    #{$scope}-projectOverviewGrid,
    #{$scope}-calculationGrid,
    #{$scope}-staffingGrid {
      grid-template-columns: 1fr;
    }

    #{$scope}-sectionTitle {
      font-size: var(--fontSize--xxl);
    }

    #{$scope}-bottomPageDescription {
      display: none;
    }

    #{$scope}-projectOverviewImage,
    #{$scope}-staffingImage {
      margin-top: 1em;
    }

    #{$scope}-projectOverviewTitle,
    #{$scope}-calculationTitle,
    #{$scope}-staffingTitle {
      line-height: 1.1;
      padding-bottom: 0.2em;
    }

    #{$scope}-projectOverviewGrid {
      margin-top: 2em;
    }
  }
}
