$scope: '.CookieBox';

#{$scope} {
  display: flex;

  &-backdrop {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 100;
    font-family: 'Futura Std Book', sans-serif;
    font-size: 12px;
  }

  &-dialog {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #000000;
    height: 240px;
  }

  &-grid {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &-privacyPreference {
    font-size: 20px;
    line-height: 24px;
  }

  &-cookiesDescription {
    margin-top: 1.6em;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    padding-bottom: 8px;
    font-family: inherit;
  }

  &-checkboxes {
    display: flex;
  }

  &-checkbox {
    line-height: 14px;
    margin-right: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &-checkboxInput {
    margin-right: 8px;
  }

  &-button {
    width: 100%;
    border: 1px solid #ffffff;
    height: 36px;
    margin-top: 8px;
    cursor: pointer;
    background-color: transparent;
    color: #ffffff;
    transition: background-color 0.3s;

    &:first-of-type {
      background-color: #ffffff;
      color: #000000;

      &:hover {
        background-color: #e6e6e6;
      }
    }

    &:hover {
      background-color: #ffffff;
      color: #000000;
    }
  }

  &--isMobileMode {
    #{$scope}-dialog {
      height: auto;
    }

    #{$scope}-grid {
      grid-template-columns: 1fr;
      padding: 2em;
    }

    #{$scope}-checkbox {
      padding: 1em 0;
    }
  }
}
