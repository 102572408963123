$scope: '.Intro';

#{$scope} {
  display: flex;
  flex-direction: column;
  padding: 2em 6em 5em;
  position: relative;

  &-pageHeading {
    margin-top: 0.1em;
    font-size: var(--fontSize--xxxl);
    font-family: 'FuturaStd-Bold', sans-serif;
    width: fit-content;
    background-image: var(--background-themeColorGradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-pageDescription {
    font-size: var(--fontSize--m);
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-top: 1em;
    margin-right: 6em;
  }

  &-pageList {
    font-size: var(--fontSize--m);
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-top: 0.8em;
    margin-right: 6em;
    color: var(--color-gray-1);
  }

  &-pageListItem {
    margin-top: 0.8em;
  }

  &-workingHeader {
    font-size: var(--fontSize--xl);
    font-family: 'LatoWebBlack', sans-serif;
    margin-top: 1em;
  }

  &-ex3000 {
    display: block;
  }

  &-leftSection {
    width: 116%;
  }

  &-ex3000Image {
    max-width: 600px;
    width: 116%;
    position: relative;
    left: 16px;
  }

  &-ligaosAndSap {
    width: 250px;
  }

  &-productsGrid {
    display: grid;
    grid-template-columns: 2.5fr 2fr;
  }

  &-gin {
    width: 100%;
  }

  &-descriptionGrid {
    display: grid;
    grid-template-columns: 56px 1fr 36px;
    grid-column-gap: 30px;
    align-items: center;
    font-size: var(--fontSize--xl);
    line-height: 32px;
    letter-spacing: 1px;
  }

  &-descriptionButton {
    background-color: var(--color-gray-3);
    border: none;
    padding: 2em 6em 2em 4em;
    border-radius: 16px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.8);
    position: relative;
    color: var(--color-white);
    transition: 0.3s background-color;
    margin: 4em 6em 0;

    &:hover {
      background-color: #2e243a;

      .GinTasting-arrow {
        animation: GinTasting-arrowAnimation 2s infinite;
        position: relative;
      }
    }
  }

  &-descriptionTitle {
    font-family: 'LatoWebBold', sans-serif;
    font-size: var(--fontSize--l);
    width: fit-content;
    background-image: var(--background-themeColorGradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: left;
  }

  &-descriptionText {
    text-align: left;
    font-size: var(--fontSize--m);
    line-height: 1.6;
    margin-top: 0.4em;
  }

  &-arrow {
    font-family: system-ui, sans-serif;
    font-size: var(--fontSize--m);
    zoom: 1.6;
  }

  &-blurBackground {
    position: absolute;
    pointer-events: none;
    z-index: -1;
  }

  &-blurBackgroundLeft {
    left: -180px;
    top: -120px;
  }

  &-blurBackgroundRight {
    right: -180px;
    top: -120px;
    transform: rotate(260deg);
    zoom: 0.9;
    opacity: 0.8;
  }

  &-bottomPageDescription {
    font-size: 1.25rem;
    font-family: 'FuturaStd-Heavy', sans-serif;
    letter-spacing: 2px;
    width: fit-content;
    background-image: var(--background-themeColorGradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: rotate(270deg) translate(0, -100%);
    transform-origin: 0 0;
    position: relative;
    right: 2em;
  }

  &--isMobileMode {
    padding: 0 1em 3em;
    overflow: auto;

    #{$scope}-productsGrid {
      grid-template-columns: 1fr;
    }

    #{$scope}-ligaosAndSap {
      display: none;
    }

    #{$scope}-leftSection {
      width: auto;
    }

    #{$scope}-pageHeading {
      margin-top: 0;
      font-size: 32px;
    }

    #{$scope}-ex3000Image {
      width: 100%;
      left: 0;
      padding: 1em 1em 0;
    }

    #{$scope}-pageDescription {
      margin-right: 0;
    }

    #{$scope}-descriptionButton {
      padding: 1em;
      margin: 0 0 2em;
    }

    #{$scope}-bottomPageDescription {
      display: none;
    }

    #{$scope}-pageList {
      margin-right: 0;
    }

    #{$scope}-descriptionTitle {
      font-size: 16px;
      line-height: 1.2;
    }

    #{$scope}-descriptionTitleDate {
      display: none;
    }

    #{$scope}-descriptionText {
      font-size: 12px;
      letter-spacing: normal;
    }

    #{$scope}-pageHeading {
      font-size: var(--fontSize--xxl);
    }

    #{$scope}-descriptionGrid {
      grid-column-gap: 10px;
      grid-template-columns: 36px 1fr 36px;

      #{$scope}-arrow {
        position: relative;
        animation: GinTasting-mobileArrowAnimation 2s infinite;
      }
    }

    #{$scope}-backgroundGradient {
      display: none;
    }
  }
}

@keyframes GinTasting-arrowAnimation {
  0%,
  100% {
    left: 0;
  }

  50% {
    left: 30px;
  }
}

@keyframes GinTasting-mobileArrowAnimation {
  0%,
  100% {
    left: 0;
  }

  50% {
    left: 6px;
  }
}
