:root {
  --maxFluidWidth: 1400px;

  --background-grayGradient: radial-gradient(
    circle at center,
    #23272d 0,
    #181c23,
    #0f1219 100%
  );

  --background-themeColorGradient: linear-gradient(
    to right,
    #b620e0 0%,
    #9c42e6 25%,
    #7c69ee 50%,
    #32c5ff 100%
  );

  --color-white: #ffffff;
  --color-gray-1: #e0e0e0;
  --color-gray-2: #939597;
  --color-gray-3: #2d303b;
  --color-gray-4: #15181f;
  --color-black: #000000;

  --fontSize--s: 0.75rem;
  --fontSize--m: 1.125rem;
  --fontSize--l: 1.375rem;
  --fontSize--xl: 1.5rem;
  --fontSize--xxl: 3rem;
  --fontSize--xxxl: 5rem;
}
