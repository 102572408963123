$scope: '.Inside';

#{$scope} {
  display: flex;
  flex-direction: column;
  padding: 4em 6em 5em;
  position: relative;

  &-sectionGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3em;
    margin-top: 1em;
  }

  &-linesColumn {
    display: flex;
    justify-content: center;
  }

  &-linesCanvas {
    width: 100%;
    display: block;
    position: relative;
    max-width: 510px;
  }

  &-linesImage {
    flex: 1;
  }

  &-linesLogo {
    width: 160px;
  }

  &-linesNode {
    background-color: var(--color-gray-3);
    border-radius: 16px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.8);
    padding: 2.2em 1.8em;
    width: max-content;
    position: absolute;
    line-height: 0;
  }

  &-linesNodeTop {
    right: -5%;
    top: 8%;
  }

  &-linesNodeMiddle {
    left: 5%;
    top: 27%;
  }

  &-linesNodeBottom {
    bottom: 14%;
    right: 9%;
  }

  &-sectionTitle {
    font-size: var(--fontSize--xxl);
    font-family: 'FuturaStd-Heavy', sans-serif;
    width: fit-content;
    background-image: var(--background-themeColorGradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-description {
    color: var(--color-gray-1);
    font-family: 'FuturaStd-Light', sans-serif;
  }

  &-bottomPageDescription {
    font-size: 1.25rem;
    font-family: 'FuturaStd-Heavy', sans-serif;
    letter-spacing: 2px;
    width: fit-content;
    background-image: var(--background-themeColorGradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: rotate(270deg) translate(0, -100%);
    transform-origin: 0 0;
    position: relative;
    right: 2em;
  }

  &-listTitle {
    font-size: var(--fontSize--l);
    margin-top: 1em;
    font-family: 'LatoWebBlack', sans-serif;
  }

  &--isMobileMode {
    padding: 0 1em 3em;
    overflow: auto;

    #{$scope}-sectionGrid {
      grid-template-columns: 1fr;
    }

    #{$scope}-linesColumn {
      padding-right: 4px;
    }

    #{$scope}-linesCanvas {
      max-width: 400px;
      font-size: 12px;
      margin: 1em 0;
    }

    #{$scope}-linesNode {
      padding: 2em 0;
    }

    #{$scope}-bottomPageDescription {
      display: none;
    }
  }
}
