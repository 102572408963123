// inspired by https://bitbucket.org/ligadigital/ln-ember-toolkit/src/master/app/styles/ln-ember-toolkit/reset.scss

html {
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
