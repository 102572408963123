.Checkbox {
  display: flex;

  &-label {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-style: italic;
    color: var(--color-gray-2);
    font-size: var(--fontSize--m);
  }

  &-control {
    width: 24px;
    height: 24px;
    position: absolute;
    opacity: 0;
    margin-right: 0.6em;
    z-index: -1;

    &:checked {
      + .Checkbox-checkmark {
        border-color: transparent;
        background-image: linear-gradient(
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0)
          ),
          var(--background-themeColorGradient);

        &:after {
          opacity: 1;
        }
      }
    }

    &:focus {
      + .Checkbox-checkmark {
        &:before {
          opacity: 1;
        }
      }
    }
  }

  &-checkmark {
    position: relative;
    min-width: 24px;
    min-height: 24px;
    margin-right: 0.6em;
    cursor: pointer;
    border-radius: 4px;
    border: solid 2px var(--color-gray-2);
    transition: 0.3s;
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px var(--color-gray-3) inset;
    line-height: 1.2;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;

    &:before {
      content: '';
      box-shadow: 0 0 0 1px var(--color-white);
      position: absolute;
      pointer-events: none;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border-radius: inherit;
      opacity: 0;
    }

    &:after {
      content: '✓';
      font-family: system-ui;
      font-weight: 600;
      width: fit-content;
      background-image: var(--background-themeColorGradient);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      opacity: 0;
    }
  }
}
