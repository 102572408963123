$scope: '.AppToaster';

#{$scope} {
  > div > div > div {
    border-radius: 16px;
    background-color: #2d303b;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.8);
    padding: 16px;
  }

  &-closeButton {
    border: none;
    background-color: transparent;
    padding: 0;
    line-height: 0;
    padding: 4px;
    cursor: pointer;
    color: var(--color-gray-3);
  }

  &-closeToast {
    width: 14px;
    color: var(--color-white);
    margin-right: 4px;
  }

  &-text {
    width: 100%;
    margin-left: 16px;
    color: var(--color-white);

    h5 {
      font-family: 'LatoWebBold', sans-serif;
      font-size: 22px;
      width: fit-content;
      background-image: var(--background-themeColorGradient);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-bottom: 8px;
    }
  }

  &--isMobileMode {
    > div > div > div {
      padding: 4px 8px;
    }

    #{$scope}-text {
      font-size: 16px;
    }

    h5 {
      padding-bottom: 4px;
    }
  }
}
